import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import classNames from 'classnames';
import { fold } from 'fp-ts/lib/boolean';
import { constant, pipe } from 'fp-ts/lib/function';
import React, { FC, Fragment, memo, useCallback, useContext } from 'react';

import { ConfigContext } from '../../../context/config.context';
import { LinksServiceContext } from '../../../context/links-service/links-service.context';
import { ServicesContext } from '../../../context/services.context';
import { SessionContext } from '../../../context/session.context';
import {
	GenericQuickResponse,
	isExternalResponse,
	isLocationResponses,
	isMessageWithSatisfactionSurveyMetadata,
	isMultiSelectResponses,
	Message,
	QuickResponseMessage,
} from '../../../models/message.model';
import { Chip, ChipProps } from '../../../ui-kit/chip/chip.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { SatisfactionSurvey } from '../../../ui-kit/user-survey/satisfaction-survey/satisfaction-survey.component';
import { EMPTY_QR_TEXT } from '../../../utils/message-parser';
import { encodeToBase64, uuid } from '../../../utils/string.utils';
import { OnSenMessageFunc } from '../../widget-wrapper/widget-wrapper.model';
import { TextRenderer } from '../text-renderer/text-renderer.component';
import { LocationFinderQuickResponseRenderer } from './location-finder/location-finder-renderer.component';
import { MultiSelectQuickResponseRenderer } from './multi-select-renderer/multi-select-renderer.component';
import { useQuickResponseRendererStyles } from './quick-responses-renderer.styles';

const EXTERNAL_ACTIONS_RESPONSES = ['webUrlExternalWithResponse', 'webUrlExternal'];
const isExternalAction = (type: string): boolean => EXTERNAL_ACTIONS_RESPONSES.includes(type);

interface ExternalActionChipProps {
	iconPosition: ChipProps['iconPosition'];
	icon: JSX.Element;
}
interface QuickResponseRendererProps {
	message: QuickResponseMessage;
	onResponse: OnSenMessageFunc;
	additionalComponents?: (JSX.Element | undefined)[];
	previousMessage?: Message;
}

export const QuickResponseRenderer: FC<QuickResponseRendererProps> = memo(
	({ message, previousMessage, onResponse, additionalComponents }) => {
		const {
			webTrackerService: { sendEvent },
		} = useContext(ServicesContext);
		const {
			state: { isWidgetDisabled, sessionToken },
		} = useContext(SessionContext);

		const {
			appOptions: { client },
			appConfig: {
				visualConfig: { chipsType },
			},
		} = useContext(ConfigContext);
		const linksService = useContext(LinksServiceContext);
		const isAdditionalComponents = additionalComponents && additionalComponents.length > 0;
		const classes = useQuickResponseRendererStyles({ isWidgetDisabled, isAdditionalComponents });
		const isMultiSelect = useCallback(isMultiSelectResponses, []);
		const isStackedChips = chipsType === 'stacked';

		const qrStyles = classNames(
			classes.buttonsContainer,
			isStackedChips && classes.stackedButtonsContainer,
			previousMessage && isMessageWithSatisfactionSurveyMetadata(previousMessage) && classes.survey,
		);

		const handleResponseClick = (
			response: GenericQuickResponse,
			e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
			flowStep: string,
			encodedSurveyData?: string,
		): void => {
			if (isExternalResponse(response)) {
				if (response.link) {
					if (response.type === 'webUrlExternal') {
						return linksService.openExternalLink(response.link, response.link, flowStep, encodedSurveyData);
					}
					if (response.type === 'webUrlExternalWithResponse') {
						linksService.openExternalLink(response.link, response.link, flowStep, encodedSurveyData);
					}
				}
			}
			sendEvent('onUserSelect', e, response.responseContext);
			onResponse(response.responseContext, response.content);
		};

		const handleMultiSelectResponse = (text: string, orininalText: string, logData: string): void => {
			sendEvent('onUserSelect', logData);
			onResponse(text, orininalText);
		};

		const renderQRButton = (response: GenericQuickResponse, flowStep: string): JSX.Element => {
			const isExternalActionQuickResponse = isExternalAction(response.type);
			const content = <span className={classes.content} dangerouslySetInnerHTML={{ __html: response.content }} />;

			const className = classNames(
				classes.customQuickResponseStyles,
				isExternalActionQuickResponse && classes.externalResponse,
			);

			const externalActionChipProps: Partial<ExternalActionChipProps> = pipe(
				isExternalActionQuickResponse,
				fold(constant({}), () => ({
					iconPosition: 'end',
					icon: (
						<Icon
							size={'large'}
							iconType={isWidgetDisabled ? 'decorIcon' : 'chipIcon'}
							icon={OpenInNewRoundedIcon}
						/>
					),
				})),
			);

			const encodedSurveyData = message.isExternal
				? encodeToBase64(
						JSON.stringify({
							client,
							flowStep,
							sessionToken,
						}),
					)
				: undefined;

			return (
				<Chip
					isDisabled={isWidgetDisabled}
					dataTestingLabel={`quick-response-btn-${response.content}`}
					onClick={(e: any) => handleResponseClick(response, e, flowStep, encodedSurveyData)}
					aria-label={response.content}
					key={response.responseContext}
					label={content}
					className={className}
					isClickableOnce={response.type !== 'webUrlExternal'}
					{...externalActionChipProps}
				/>
			);
		};

		// add state machine
		const renderResponses = (message: QuickResponseMessage, previousMessage?: Message) => {
			const { responses, flowStep } = message;
			if (previousMessage && isMessageWithSatisfactionSurveyMetadata(previousMessage)) {
				const { responses, flowStep } = message;
				const normalQR = responses.slice(previousMessage.metadata.data.buttons.length);
				return (
					<Fragment>
						<SatisfactionSurvey
							quickResponsesMessage={message}
							satisfactionConfigButtons={previousMessage.metadata.data.buttons}
							onAction={handleResponseClick}
						/>
						<div
							className={classNames(
								classes.lastQrpadding,
								isStackedChips && classes.stackedButtonsSatisfactionContainer,
							)}>
							{normalQR.map((response) => renderQRButton(response, flowStep))}
						</div>
					</Fragment>
				);
			}

			if (isMultiSelect(responses)) {
				return (
					<MultiSelectQuickResponseRenderer
						key={uuid()}
						responses={responses}
						onResponse={handleMultiSelectResponse}
						isDisabled={isWidgetDisabled}
					/>
				);
			}
			if (isLocationResponses(responses)) {
				return (
					<LocationFinderQuickResponseRenderer
						responses={responses}
						onResponse={onResponse}
						className={isStackedChips ? classes.stackedButtonsContainer : ''}
					/>
				);
			}

			return responses.map((response) => renderQRButton(response, flowStep));
		};

		// if QR doesn't has a text it's changes to '_' https://github.com/GYANTINC/gyant-reasoning/blob/develop/src/api/controllers/ConversationService.js#L311-L312
		const isEmptyText = message.text === EMPTY_QR_TEXT || !message.text;
		return (
			<Fragment>
				{!isEmptyText && (
					<TextRenderer
						message={message.text}
						flowStep={message.flowStep}
						isUserMessage={false}
						isUndoable={false}
					/>
				)}
				{additionalComponents}
				<div className={qrStyles} data-testing-label={'quick-responses-container'} aria-label={message.text}>
					{renderResponses(message, previousMessage)}
				</div>
			</Fragment>
		);
	},
);
